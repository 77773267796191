@charset "utf-8";

$sugar-cane-700: #2c3b0c; // 700
$sugar-cane-500: #506138; // 500
$sugar-cane-400: #748361; // 400
$sugar-cane-200: #9ca98e; // 200
$sugar-cane-80: #c4cebb; // 80
$sugar-cane-20: #f9fff4; // 20

$snow-flurry-700: #2e360a; // 700
$snow-flurry-600: #3f592b; // 600
$snow-flurry-500: #567242; // 500
$snow-flurry-300: #749160; // 300
$snow-flurry-100: #98b482; // 100
$snow-flurry-80: #bdd7a4; // 80
$snow-flurry-20: #e8ffcb; // 20

$color-7: $snow-flurry-700;
$color-6: $snow-flurry-600;
$color-5: $snow-flurry-500;
$color-4: $snow-flurry-300;
$color-3: $snow-flurry-100;
$color-2: $snow-flurry-80;
$color-1: $snow-flurry-20;
$color-0: $sugar-cane-20;

$scheme-main: $color-1;
$background: $color-1;
$primary: $color-4;
$footer-background-color: rgba($color-6, 0.7);

$text-strong: $color-7;
$text: $color-6;
$link: $color-5;
$subtitle-color: $color-6;
$border: $color-3;
$button-border-color: $color-4;

$navbar-background-color: transparent;
$navbar-fixed-z: 31;

$pagination-current-background-color: $color-4;

$table-background-color: transparent;
$table-head-background-color: $color-3;
$table-head-cell-color: $color-4;
$table-cell-border: $color-1;
$table-cell-border-width: 0 0 1px;
$table-row-hover-background-color: $color-2;

@import "~bulma/bulma.sass";

.has-background-transparent {
    background-color: transparent;
}

.has-background-color-0 {
    background-color: rgba($color-0, 0.95);
}

body {
  background-color: $color-2;
  background-image: url('/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body, button, input, textarea {
    font-family: "游明朝",
                 YuMincho,
                 "Hiragino Mincho ProN W3",
                 "ヒラギノ明朝 ProN W3",
                 "Hiragino Mincho ProN",
                 "HG明朝E",
                 "ＭＳ Ｐ明朝",
                 "ＭＳ 明朝",
                 serif;
}

.hidden {
    display: none;
}

.app-header {
    background-color: $color-7;
    h1 {
        a:hover {
            opacity: 0.5;
        }
    }
}

.select_view-nav {
    text-align: right;

    button {
        margin: 1% 0.25em 0em 0.25em;
        padding: 0.5em;
        border: #000 solid 1px;
        border-radius: 2px;
        min-width: 4em;
        background: #fff;
    }
}

.app-body {
    margin: 3.0em 0em 0em 0em;
}

.top-container {
    background-color: rgba($color-0, 0.95);
    > .content {
        line-height: 1.8em;
    }
}

.list-container {
    background-color: rgba($color-0, 0.95);
    padding-left: 1em;
    line-height: 1.8em;
}

.sidebar-container {
    padding: 1em 1em 1em 1em;
    background-color: rgba($color-0, 0.8);
    box-shadow: -2px 2px 2px 0px rgba($color-2, 0.2);
}

.analysis-container {
    background-color: rgba($color-0, 0.95);
    padding: 1em;
}

.search-list {
    li {
        list-style: none;
        margin-left: -3em;
        padding: 0.6em 1em 0.6em 2em;
    }
    .search-item:hover {
        background-color: rgba($color-2, 0.9);
        /*background: rgb(249,255,244);*/
        background: linear-gradient(90deg,
                                    rgba($color-2, 0.0) 0%,
                                    rgba($color-2, 0.3) 2%,
                                    rgba($color-3, 0.3) 98%,
                                    rgba($color-3, 0.0) 100%);
    }
}

.search-title {
    margin: 0px 0px 0.35em 0px !important;
}

.search-subtitle {
    margin: 0px 0px 0.20em 1em !important;
}

.search-content {
    margin: 0px 0px 0px 1em !important;
}

.search-location, .search-detail {
    color: $text;
}

.pages ul {
    list-style: none;
}

.pages li {
    /* mysterious margin inserted into .content li + li */
    margin: 0px !important;
}

.pages a:hover {
    background-color: $color-2;
}

.container-preview {
}

.container-export {
    width: calc(100% - 2em);
    height: 75vh;
    padding: 0.5em 1em 1em 1em;
}

.container-export pre {
    width: calc(100% - 1em);
    height: 100%;
    border: 1px solid #000;
    background-color: #eee;
    text-align: left;
    padding: 0.5em;
    overflow: scroll;
}

@import "styles/editor.scss";

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} { @content; }
    @-moz-keyframes    #{$name} { @content; }
    @-ms-keyframes     #{$name} { @content; }
    @keyframes         #{$name} { @content; }
}

@include keyframes(fadein-1) {
    0%   { opacity: 0.2; }
    100% { opacity: 0.9; }
}

@include keyframes(fadeout-1) {
    0%   { opacity: 0.9; }
    100% { opacity: 0.0; }
}

.scrollup-button {
    position: fixed;
    bottom: 1%;
    right: 1%;
    width: 64px;
    height: 64px;
    .top {
        display: none;
    }
    .notop {
        opacity: 0.2;
    }
    .notop:hover {
        opacity: 0.9;
        animation: fadein-1 0.5s ease 1;
    }
}

.comment-container {
    padding: 1em;
}

ul.comments-list {
    list-style: none outside;
    margin-left: 0px;
}

.comment-body {
    div {
        margin-left: 1em;
        padding: 0.2em 1em 0.2em 1em;
        border-left: solid 6px $color-2;
    }
}

.comment-images {
    list-style: none !important;
    margin: 0px !important;
    text-align: right;
    a {
        display: inline-block;
    }
}

.comment-item {
    margin: 0.5em 0em 1.0em 0em;
    padding: 0.5em 0.5em 1.5em 0.5em;
    border-bottom: solid 1px $color-2;
    .level {
        margin-bottom: 1.0em;
    }
}

.comment-toolbox {
    display: flex;
    justify-content: flex-end;

    > * {
        align-self: flex-end;
    }

    a {
        display: inline-block;
        line-height: 1.1em;
        margin-right: 0.5rem;
        > * {
            width: 1.1rem;
            height: 1.1rem;
        }
    }
}

.footer {
    color: $color-0;
    a {
        color: $color-2;
    }
}

.sup {
    font-size: 0.7em;
    opacity: 0.7;
    vertical-align: super;
}

.is-low {
    ._column:not(:first-child) {
        margin-top: -0.75em !important;
    }
    margin-bottom: 0px !important;
}
